<template>
  <b-card title="">
    <b-row class="justify-content-between d-flex">
      <b-col>
        <!-- DATA INI -->
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Data inicio :</label
          >
          <b-form-input
            id="transactionDate"
            type="date"
            v-model="filters.startDate"
            @change="getAll"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- DATA FIM -->
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Data fim :</label
          >
          <b-form-input
            id="transactionDate"
            type="date"
            v-model="filters.endDate"
            @change="getAll"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-2 mr-2"
            variant="primary"
            @click="openModalDefault"
          >
            Novo item +
          </b-button>
        </b-row></b-col
      >
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <!-- CATEGORY -->
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Categoria :</label
          >
          <b-form-select
            id="category"
            placeholder="Selecione uma categoria"
            v-model="filters.category_id"
            @change="getAll"
          >
            <option value="">Todos as categorias</option>
            <option
              v-for="categories in filters.categories"
              :key="categories.id"
              :value="categories.id"
            >
              {{ categories.expense_category_name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- FORNECEDORES -->
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Fornecedor :</label
          >
          <b-form-select
            id="category"
            v-model="filters.supplier_id"
            @change="getAll"
          >
            <option value="">Todos os fornecedores</option>
            <option
              v-for="supplier in filters.supplier"
              :key="supplier.id"
              :value="supplier.id"
            >
              {{ supplier.supplier_name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Status :</label
          >
          <b-form-select
            id="category"
            v-model="filters.status"
            @change="getAll"
          >
            <option value="">Todos</option>
            <option value="realizado">realizados</option>
            <option value="previsto">previsto</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mb-2">
          <label for="category" class="font-weight-bold text-primary"
            >Condição de pagamento :</label
          >
          <b-form-select
            id="category"
            v-model="filters.type"
            @change="getAll"
          >
            <option value="">Todos</option>
            <option value="À vista (uma vez)">À vista (uma vez)</option>
            <option value="Parcelado">Parcelado</option>
            <option value="Recorrência">Recorrência</option>

          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="items"
      :fields="fields"
      class="p-1 w-100 table-details"
      show-empty

    >

      <template v-slot:cell(actions)="{ item }">
        <Actions
          @openModal="openEditModal(item)"
          @openModaDelete="openDeleteModal(item)"
          :item="item"
        />
      </template>
      <template v-slot:cell(value)="{ item }">
        {{ `R$ ${numberToMonetary(item.value)}` }}
      </template>
      <template #empty>
        <b-alert show variant="primary">
          <p class="text-center">
            <br />
            Não existem <strong>Custos de projetos nos filtros selecionados.</strong> Tente aplicar outros filtros.
          </p>
          <hr />
        </b-alert>
      </template>
    </b-table>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de registros: {{ totalRows }} </strong>
    </div>
    <Modal
      :modalItem="modalItem"
      :modalItemForm="modalItemForm"
      :editable="editable"
      :itemUpdate="itemUpdate"
      @updateList="handleUpdateList"
      @renderList="getAll"
      @closeModal="closeModal()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCol,
  BAlert
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import Actions from './components/ActionsProjectCosts.vue';
import Modal from './components/ModalProjectCosts.vue';
import { format, startOfMonth, endOfMonth } from 'date-fns';
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    Actions,
    BFormSelect,
    Modal,
    BAlert
  },
  data: () => ({
    modalItem: false,
    modalItemForm: false,
    editable: false,
    itemUpdate: null,
    items: [],
    group: 'offices',
    totalRows: '',
    search: '',
    filters: {
      supplier: [],
      categories: [],
      project_name: '',
      expense_category_name: '',
      supplier_name: '',
      supplier_id: '',
      category_id: '',
      status: '',
      startDate: null,
      endDate: null,
      type: ''
    },
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Data de referência',
        sortable: true,
      },
      {
        key: 'transaction_date',
        label: 'Data da transação',
        sortable: true,
      },

      {
        key: 'value',
        label: 'Valor',
        sortable: true,
      },
      {
        key: 'expenseCategory.expense_category_name',
        label: 'Categoria de custos',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'type',
        label: 'Condição de Pagamento',
        sortable: true,
      },
      {
        key: 'reference_date',
        label: 'Mês de referência',
        sortable: true,
      },
    ],
  }),

  created() {
    let startDate = startOfMonth(new Date());
    this.filters.startDate = format(startDate, 'yyyy-MM-dd');

    let endDate = endOfMonth(new Date());
    this.filters.endDate = format(endDate, 'yyyy-MM-dd');

    this.getAll();
    this.loadProjects();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    depreciableAssetsFilteres() {
      let values = [];
      values = this.items.filter((item) => {
        return (
          item.item_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
      return values;
    },
  },

  methods: {
    formatDate(date, full) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é baseado em zero
      const year = date.getFullYear();

      if (full) {
        return `${day}/${month}/${year}`;
      } else {
        return `${month}/${year}`;
      }
    },
    getAll() {
      this.items = [];
      this.$store
        .dispatch('getAllProjectCosts', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          start_date: this.filters.startDate,
          end_date: this.filters.endDate,
          supplier_id:this.filters.supplier_id == '' ? null : this.filters.supplier_id,
          category_id:this.filters.category_id == '' ? null : this.filters.category_id,
          type: this.filters.type == '' ? null : this.filters.type,
          status: this.filters.status == '' ? null : this.filters.status,
          is_project_cost: false,
        })
        .then((resp) => {
          const array = [];
          if (resp) {
            resp.transactions.map((item) => {
              array.push({
                ...item,
                reference_date: this.dateFormatter(
                  new Date(item.reference_date),
                  'MM/yyyy'
                ),
                transaction_date: this.dateFormatter(
                  new Date(item.transaction_date),
                  'dd/MM/yyyy'
                ),
              });
            });
            this.items = array;
            this.totalRows = this.items.length
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filter() {
      return this.items.filter((item) => {
        const startDatePass =
          !this.filters.startDate ||
          new Date(item.created_at) >= new Date(this.filters.startDate);
        const endDatePass =
          !this.filters.endDate ||
          new Date(item.created_at) <= new Date(this.filters.endDate);

        return (
          startDatePass &&
          endDatePass &&
          (this.filters.project_name === '' ||
            item.project_name
              .toLowerCase()
              .includes(this.filters.project_name.toLowerCase())) &&
          (this.filters.expense_category_name === '' ||
            item.expense_category_name
              .toLowerCase()
              .includes(this.filters.expense_category_name.toLowerCase())) &&
          (this.filters.supplier_name === '' ||
            item.supplier_name
              .toLowerCase()
              .includes(this.filters.supplier_name.toLowerCase())) &&
          (this.filters.status === '' ||
            item.status
              .toLowerCase()
              .includes(this.filters.status.toLowerCase()))
        );
      });
    },
    async loadProjects() {
      try {
        await this.$store
          .dispatch('getAllSuppliers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            if (resp) {
              this.filters.supplier = resp;
            }
          });

        await this.$store
          .dispatch('getAllCostsCategory', {
            inactive: false,
            workspace_id: this.$store.getters.currentWorkspace.id,
            perPage: 1000,
            office_cost: true,
          })
          .then((resp) => {
            if (resp) {
              this.filters.categories = resp.data;
            }
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },

    cleanFilter() {
      this.search = '';
    },

    openEditModal(item) {
      this.modalItem = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalItemForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modalItem = true;
      this.modalItemForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modalItem = true;
      this.modalItemForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modalItem = false;
      this.editable = false;
    },
    handleUpdateList() {
      this.getAll();
    },
  },
};
</script>
<style>
.table-details {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
