<template>
  <div>
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar custo de escritório"
      icon="EditIcon"
      size="15"
        @click="$router.push(`/lancamento-de-despesa/${item.id}`)"
      style="cursor: pointer;"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  name: 'actions-projectCosts',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
